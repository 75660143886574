<template>
  <div class="chartdiv" ref="chartdiv"></div>
</template>

<script>
import { mapActions } from 'vuex';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
// import am4themesDark from '@amcharts/amcharts4/themes/dark';

am4core.useTheme(am4themesAnimated);
/**
 * User Specific Activity view
 * @displayName ActivityType View
 */

export default {
  name: 'ActivityTypeView',

  methods: {
    ...mapActions({
      graphDumpFetch: 'graphDumpFetch',
    }),
  },

  created() {
    this.graphDumpFetch();
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'setGraph') {
        const graphFromState = JSON.parse(JSON.stringify(this.$store.state.graph));
        const userInfo = JSON.parse(JSON.stringify(this.$store.state.userInfo));

        const filteredNodeList = graphFromState.nodes.filter((x) => x.labels.includes('User'));
        const filteredEdgeList = graphFromState.links;

        const userForPlot = filteredNodeList.filter((user) => user.discriminator === userInfo?.discriminator)[0];
        // const edgesForPlot = filteredEdgeList.filter((edge) => edge.source === userForPlot.id);
        const platformsForPlot = filteredEdgeList.filter((edge) => edge.source === userForPlot.id).map((edge) => edge.label);

        // eslint-disable-next-line
        const dataToPlot = platformsForPlot.reduce((prev, curr) => (prev[curr] = ++prev[curr] || 1, prev), {});

        this.chart.data = Object.keys(dataToPlot).map((type) => (
          {
            Activity_Type: type,
            value: dataToPlot[type],
          }
        ));
      }
    });
  },

  mounted() {
    const chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 1; // this creates initial fade-in

    chart.innerRadius = am4core.percent(30);
    chart.depth = 80;

    chart.legend = new am4charts.Legend();

    const series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = 'value';
    series.dataFields.depthValue = 'value';
    series.dataFields.category = 'Activity_Type';
    series.slices.template.cornerRadius = 5;
    series.colors.step = 3;
    this.chart = chart;
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.chartdiv {
  width: 100%;
  height: 31.25em;
}
</style>
